// 3rd party
import { useEffect, useState, useRef } from 'react'

// Helpers
import { getColorName, isEmpty, getContentModel } from '@/lib/helper'

// Styles
import styleConfig from '@/styles/style-config'

// Contentful
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { enableHeroSectionPreview } from '@/lib/contentful/module'
import { NextImage } from '@/components/Image/NextImage'
import { getModalData } from '@/lib/contentful/modal'
import { viewContentPromotionEvent } from '@/lib/ga-events'

import { default as pagePaths } from '../../public/promotion-urls.json'
import useIsInViewport from '@/hooks/useIntersection'

// Components
// import Icon from '@/components/Icon/Icon'
const Icon = dynamic(() => import('@/components/Icon/Icon').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})
// import CTA from '@/components/CTA'
const CTA = dynamic(() => import('@/components/CTA').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})
// import HeroBlogAuthor from './HeroBlogAuthor'
const HeroBlogAuthor = dynamic(() => import('./HeroBlogAuthor').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})
// import ProfileModuleHero from '../Account/ProfileModuleHero'
const ProfileModuleHero = dynamic(
  () => import('../Account/ProfileModuleHero').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleHeroVideo from '../Hero/ModuleHeroVideo'
const ModuleHeroVideo = dynamic(
  () => import('../Hero/ModuleHeroVideo').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)

const ProductPopUp = dynamic(() => import('../Product/ProductPopUp').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})
// Constants
import { markdownRenderOptions } from '@/constants/markdownRenderOptions'
import dynamic from 'next/dynamic'

// Hooks
import useMediaQuery from '@/hooks/useMediaQuery'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { getSearchSpringData, getAutocompleteData } from '@/lib/searchspring'
import { productListViewEvent, prepareViewListItems } from '@/lib/ga-events'

const ModuleHeroSection = (props) => {
  let pageModel = getContentModel('page')
  const { preview } = props
  const ref = useRef(null)
  const inViewport = useIsInViewport(ref)
  const [item, setItem] = useState(props)
  const [productsList, setProductsList] = useState([])
  const [showProductMenu, setShowProductMenu] = useState(false)
  const [gaData, setGaData] = useState(null)
  const [success, setSuccess] = useState(false);

  const router = useRouter()
  const customer = useSelector((state) => state.userReducer)

  const isDesktop = useMediaQuery(`(min-width: ${styleConfig.theme.screens.lg})`)
  const isTablet = useMediaQuery(
    `(min-width: ${styleConfig.theme.screens.md}) and (max-width: ${
      styleConfig.theme.screens.lg?.replace('px', '') - 1
    }px )`
  )

  const getDesktopImage = (item) => {
    const heroImage = item?.modules?.length ? item?.modules[0] : item?.modules
    let desktopImg =
      heroImage && heroImage.fields?.desktop?.fields?.file?.url
        ? 'https:' + heroImage.fields?.desktop?.fields?.file?.url
        : item?.backgroundImage || "https:" + item?.backgroundImage?.fields?.file?.url || ''
    desktopImg = desktopImg == '' ? null : desktopImg
    return desktopImg
  }
  const getTabletImage = (item) => {
    const heroImage = item?.modules?.length ? item?.modules[0] : item?.modules
    let tabletImg =
      heroImage && heroImage?.fields?.tablet?.fields?.file?.url
        ? 'https:' + heroImage?.fields?.tablet?.fields?.file?.url
        : heroImage && heroImage?.fields?.desktop?.fields?.file?.url
        ? 'https:' + heroImage?.fields?.desktop?.fields?.file?.url
        : item?.backgroundImage || "https:" + item?.backgroundImage?.fields?.file?.url || ''
    tabletImg = tabletImg == '' ? null : tabletImg
    return tabletImg
  }
  const getMobileImage = (item) => {
    const heroImage = item?.modules?.length ? item?.modules[0] : item?.modules
    let mobileImg =
      heroImage && heroImage?.fields?.mobile?.fields?.file?.url
        ? 'https:' + heroImage?.fields?.mobile?.fields?.file?.url
        : heroImage && heroImage?.fields?.tablet?.fields?.file?.url
        ? 'https:' + heroImage?.fields?.tablet?.fields?.file?.url
        : heroImage && heroImage?.fields?.desktop?.fields?.file?.url
        ? 'https:' + heroImage?.fields?.desktop?.fields?.file?.url
        : item?.backgroundImage || "https:" + item?.backgroundImage?.fields?.file?.url || ''
    mobileImg = mobileImg == '' ? null : mobileImg
    return mobileImg
  }
  const getAltTag = (item) => {
    const altTag = item?.modules?.length ? item?.modules[0] : item?.modules
    let mediaAltTag =
      altTag && altTag?.fields?.altText
        ? altTag?.fields?.altText
        : altTag && altTag?.fields?.mobile?.fields?.description
        ? altTag?.fields?.mobile?.fields?.description
        : altTag && altTag?.fields?.tablet?.fields?.description
        ? altTag?.fields?.tablet?.fields?.description
        : altTag && altTag?.fields?.desktop?.fields?.description
        ? altTag?.fields?.desktop?.fields?.description
        : item.altTag || ''
    return mediaAltTag
  }

  const getContentPosition = (item) => {
    let position = ''
    switch (item?.heroType) {
      case 'Hero Center Left':
        position = 'center-left'
        break
      case 'Hero Center Right':
        position = 'center-right'
        break
      case 'Hero Bottom Right':
        position = 'bottom-right'
        break
      case 'Hero Bottom Center':
        position = 'bottom-center'
        break
      case 'Hero Bottom Left':
        position = 'left'
        break
      case 'Hero Center Overlay Text':
        position = 'overlay-text'
        break
      case 'Page Header Bottom Left':
        position = 'left'
        break
      case 'Hero PLP Custom':
        position = 'left'
        break
      case 'Navigation Content':
        position = 'bottom-left'
        break
      default:
        position = 'center'
        break
    }
    return position
  }

  const getHeroClassName = (item) => {
    let classname = getContentPosition(item)
    switch (classname) {
      case 'left':
        return 'hero-bottom-left-block'
      case 'center-left':
        return 'hero-center-left-block'
      case 'center-right':
        return 'hero-center-right-block'
      case 'bottom-right':
        return 'hero-bottom-right-block'
      case 'bottom-center':
        return 'hero-bottom-center-block'
      case 'overlay-text':
        return 'hero-center-overlay-text-block'
      case 'bottom-left':
        return 'hero-bottom-left-block'
      default:
        return 'hero-center-block'
    }
  }

  const getTextColor = () => {
    const overlay = item?.overlayType || 'Light'
    switch (overlay) {
      case 'Dark':
        return 'white'
      case 'Empty':
        return 'white'
      case 'Light':
        return 'black'
    }
  }

  useEffect(async () => {
    if (preview && String(item?.heroType).toLowerCase().replace(/ /g, '-') !== 'hero-blog-author') {
      const res = await enableHeroSectionPreview(props, preview)
      setItem(res)
    } else {
      setItem(props)
    }
  }, [props, preview])

  const ctaLeft = {
    id: item?.ctaLeft?.id || null,
    label: item?.ctaLeft?.label || item?.ctaLeft?.fields?.label || item?.ctaRightText,
    openInNewTab: item?.ctaLeft?.openInNewTab || item?.ctaLeft?.fields?.openInNewTab || false,
    openVideoPlayer: item?.ctaLeft?.openVideoPlayer || item?.ctaLeft?.fields?.openVideoPlayer || false,
    url: item?.ctaLeft?.url || item?.ctaLeft?.fields?.url || item?.ctaRightUrl,
    bgColor: item?.ctaLeft?.backgroundColor || item?.ctaLeft?.fields?.backgroundColor,
  }

  const ctaRight = {
    id: item?.ctaRight?.id || null,
    label: item?.ctaRight?.label || item?.ctaRight?.fields?.label || item?.ctaRightText,
    openInNewTab: item?.ctaRight?.openInNewTab || item?.ctaRight?.fields?.openInNewTab || false,
    openVideoPlayer: item?.ctaRight?.openVideoPlayer || item?.ctaRight?.fields?.openVideoPlayer || false,
    url: item?.ctaRight?.url || item?.ctaRight?.fields?.url || item?.ctaRightUrl,
    bgColor: item?.ctaRight?.backgroundColor || item?.ctaRight?.fields?.backgroundColor,
  }

  const showRightCta =
    ctaRight.url &&
    ctaRight.label &&
    item?.heroType !== 'Hero PLP Filter Center' &&
    item?.heroType !== 'Hero PLP Filter White BG'

  const ctaClassName = `btn btn-white leading-normal tracking-widest md:px-[24px] desktop-btn`

  const buttonStyle = `
  .desktop-btn {
    padding:16px;
    line-height: 125%;
    margin-top: 10px;
	  margin-bottom: 10px;
    min-width:159px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  @media (min-width: ${styleConfig.theme.screens.lg}) {
    .desktop-btn {
      width: 250px;
      height: 56px;
      padding: 20px 0;
      font-size: 14px;
      line-height: 125%;
    }
  }
  `

  useEffect(() => {
    if (item?.productData?.length > 0) {
      getProductData()
    }
  }, [])

  useEffect(() => {
    if (showProductMenu && gaData) {
      productListViewEvent(gaData)
    }
  }, [showProductMenu])

  const getProductData = async () => {
    let productSearchData = []
    await Promise.all(
      item?.productData?.map(async (product) => {
        const response = await getAutocompleteData(product?.fields?.productId)

        let results = null

        if (response?.results) {
          results = response?.results
        }

        if (results && results?.length > 0) {
          let variantCollection =
            results[0] && JSON.parse(results[0]?.ss_variants?.replace(/&quot;/g, '"'))
          let variantData = {}
          const foundItem =
            variantCollection?.length &&
            variantCollection?.find((item) => {
              return item.sizes.some((size) => size.sku === product?.fields?.productId)
            })
          variantData = {
            productSlug: `/products/${results[0].url}/${getColorName(foundItem?.color)}`,
            productName: results[0]?.name,
            productImage: foundItem?.image,
            productPrice: foundItem?.price,
            productId: product?.fields?.productId,
          }
          productSearchData.push(variantData)
        }
      })
    )

    if (productSearchData?.length > 0) {
      let newGaProducts = await prepareViewListItems(productSearchData, 'Hero')

      if (newGaProducts?.length > 0) {
        productSearchData = productSearchData?.map((product) => {
          let skuPartials = product?.productId?.split('-')
          let gaProduct = newGaProducts?.find((product) => product?.sku == skuPartials?.[0])

          return {
            ...product,
            gaData: gaProduct || null,
          }
        })

        setGaData(newGaProducts)
      }
    }
    setProductsList(productSearchData)
  }

  useEffect(() => {
    setSuccess(false)
  }, [router.asPath])

  useEffect(() => {
    if(inViewport) {
      if (success) return;
      const sucessFunction = async () => {
        try {
          let currentPath = router.asPath;
          let promotionId = (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL)+currentPath;
          promotionId = promotionId.startsWith('http') ? promotionId : 'https://'+promotionId

          //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
          let promotionName
          let creativeName
          let creativeSlot
          let locationId
          if(currentPath == '/') {
            promotionName = pageModel.name
          } else {
            let pageType = pagePaths.find(page => page.path == currentPath)
            promotionName = pageType?.type ? getContentModel(pageType?.type)?.name : pageModel.name
            // promotionName = promotionName+' :: Module | Hero Section'
          }
          let allowTrigger = false
          if(props) {
            let contentType = props?.sys?.contentType?.sys?.id || props?.contentType
            creativeName = props?.entryTitle || props?.fields?.entryTitle || props?.textAndImage || props?.sectionName
            locationId = props?.index ? props?.index + 1 : 1
            let articleCheck = getContentModel('article')?.name
            if(promotionName.includes(articleCheck)) {
              locationId += 1
            }
            creativeSlot = `Modules::${locationId}`
            if(props?.categoryLevel) {
              locationId = 'Navigation::'+props?.categoryLevel
              creativeSlot = 'Marketing Content'
            }
            if(contentType) {
              let contentModel = getContentModel(contentType)
              switch (contentType) {
                case 'moduleHero':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleNavigationContentBlock':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}::${heroModel.name}`
                  break;
                case 'moduleHeroSection':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleContent2Images':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleContent2Blocks':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'productFeatures':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleContent3Blocks':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'moduleGlobalBrands':
                  promotionName = `${promotionName}::${contentModel.name}`
                  allowTrigger = false
                  break;
                case 'modulePageDescription':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                case 'module3upFeature':
                  allowTrigger = true
                  promotionName = `${promotionName}::${contentModel.name}`
                  break;
                default:
                  break;
              }
            }
          }
          let eventData = [
            {
              promotion_id: promotionId,
              promotion_name: promotionName,
              creative_name: creativeName,
              creative_slot: creativeSlot,
              location_id: locationId
            }
          ]
          let executed = false
          if(promotionId && promotionName && creativeName && creativeSlot && locationId && allowTrigger) {
            executed = viewContentPromotionEvent(eventData)
          }
          if(executed) {
            setSuccess(true);
          }
        } catch (error) {
          console.error('View Promotion execution failed:', error);
        }
      };
  
      const intervalId = setInterval(async () => {
        if (!success) {
          await sucessFunction();
        } else {
          clearInterval(intervalId);
        }
      }, 500);
  
      return () => clearInterval(intervalId);
    }
  }, [inViewport, success, router.asPath])

  return (
    <div ref={ref}>
      {String(item?.heroType).toLowerCase().replace(/ /g, '-') === 'hero-blog-author' && (
        <HeroBlogAuthor
          item={item}
          getDesktopImage={getDesktopImage}
          getTabletImage={getTabletImage}
          getMobileImage={getMobileImage}
          getContentPosition={getContentPosition}
          getHeroClassName={getHeroClassName}
          getTextColor={getTextColor}
          getAltTag={getAltTag}
          preview={preview}
          data={props}
        />
      )}
      {String(item?.heroType).toLowerCase().replace(/ /g, '-') === 'hero-customer-profile' && (
        <ProfileModuleHero
          item={item}
          getDesktopImage={getDesktopImage}
          getTabletImage={getTabletImage}
          getMobileImage={getMobileImage}
        />
      )}
      {String(item?.heroType).toLowerCase().replace(/ /g, '-') === 'hero-video-background' && (
        <ModuleHeroVideo {...props} />
      )}
      {String(item?.heroType).toLowerCase().replace(/ /g, '-') !== 'hero-blog-author' &&
        String(item?.heroType).toLowerCase().replace(/ /g, '-') !== 'hero-customer-profile' &&
        String(item?.heroType).toLowerCase().replace(/ /g, '-') !== 'hero-video-background' && (
          <div className={`${item?.spaceBelowContent ? 'pb-15 md:pb-[100px]' : ''}  ${
            String(item?.heroType)?.toLowerCase()?.replace(/ /g, '-') === 'navigation-content' && 'h-[470px]'
          }`}>
            <div
              className={` ${String(item?.heroType)?.toLowerCase()?.replace(/ /g, '-')} ${
                item?.fullHeight ? 'full-height' : ''
              } ${item?.inset ? 'mx-[24px] lg:mx-[80px]' : ''} relative bg-center bg-cover ${
                item?.isPLP &&
                String(item?.heroType)?.toLowerCase()?.replace(/ /g, '-') !== 'hero-plp-custom'
                  ? 'min-h-[150px] md:min-h-[200px]'
                  : ''
              } ${
                String(item?.heroType)?.toLowerCase()?.replace(/ /g, '-') === 'navigation-content' && 'h-[470px]'
              }
              ${
                String(item?.heroType)?.toLowerCase()?.replace(/ /g, '-').includes('hero-center') && 'min-h-[470px]'
              }
               ${item?.useParallaxBackground && 'bg-fixed'}`}
            >
              {item?.heroType !== 'Hero PLP Filter White BG' && (
                <>
                  <div className={`z-10 bg-[#fff] imageBackground desktop-image-background`}>
                    {getDesktopImage(item) && (
                      <NextImage
                        src={`${getDesktopImage(item)}`}
                        layout="fill"
                        alt={`${getAltTag(item)}`}
                        quality={90}
                        objectFit="cover"
                        objectPosition="center"
                        priority={item?.index == 0 ? true : false}
                      />
                    )}
                  </div>
                  <div className="z-10 bg-[#fff] imageBackground tablet-image-background">
                    {getTabletImage(item) && (
                      <NextImage
                        src={`${getTabletImage(item)}`}
                        layout="fill"
                        alt={`${getAltTag(item)}`}
                        quality={90}
                        objectFit="cover"
                        objectPosition="center"
                        priority={item?.index == 0 ? true : false}
                      />
                    )}
                  </div>
                  <div className={`z-10 bg-[#fff] imageBackground mobile-image-background`}>
                    {getMobileImage(item) && (
                      <NextImage
                        src={`${getMobileImage(item)}`}
                        width={418}
                        height={640}
                        alt={`${getAltTag(item)}`}
                        quality={90}
                        objectFit="cover"
                        objectPosition="center"
                        priority={(item?.index !== 0 && false) || true}
                      />
                    )}
                  </div>
                </>
              )}
              {item?.heroType !== 'Hero PLP Bottom Spread' && (
                <div
                  className={`flex ${
                    item?.inset ? 'xs:max-w-[250px] md:max-w-[600px] max-w-unset' : ''
                  } ${item?.heroType === "Navigation Content" ? 'justify-start bottom-5 left-2' : `${getHeroClassName(item)}`} absolute items-center text-center ${
                    getContentPosition(item) === 'overlay-text'
                      ? 'md:max-w-[548px]'
                      : 'xs:max-w-full md:max-w-[600px]'
                  }  max-w-none ${
                    props.fullHeight && props.firstOnPage && !props.categoryName
                      ? 'top-[110px] lg:top-[150px]'
                      : ''
                  }`}
                >
                  <div className={`hero-text-color relative px-6`}>
                    {!isEmpty(item?.eyebrow) && (
                      <p
                        className={`font-bold leading-tight text-xs md:text-sm uppercase ${
                          getContentPosition(item) === 'left'
                            ? 'text-left'
                            : getContentPosition(item) === 'center-left'
                            ? 'text-left'
                            : getContentPosition(item) === 'center-right' ||
                              getContentPosition(item) === 'bottom-right'
                            ? 'text-right'
                            : 'text-center'
                        }`}
                      >
                        {item.eyebrow}
                      </p>
                    )}
                    {!isEmpty(item?.headline) && <h1 className="hidden">{item.categoryName}</h1>}
                    {(!isEmpty(item?.headline) || !isEmpty(item?.categoryName)) && (
                      <h1
                        className={`text-3xl  ${
                          getContentPosition(item) === 'left'
                            ? 'text-left'
                            : getContentPosition(item) === 'overlay-text'
                            ? 'overlay-text text-left'
                            : getContentPosition(item) === 'center-left'
                            ? 'text-left'
                            : getContentPosition(item) === 'center-right' ||
                              getContentPosition(item) === 'bottom-right'
                            ? 'text-right'
                            : 'text-center'
                        }
                           uppercase text-[32px] md:text-[50px] font-bold leading-[38px] md:leading-[50px] ${
                             item.spaceBelowTextContent
                               ? item?.isPLP
                                 ? 'mt-[32px] md:mt-[48px]'
                                 : 'mt-[25px]'
                               : 'my-3'
                           }`}
                      >
                        {!isEmpty(item?.headline) ? item.headline : item.categoryName}
                      </h1>
                    )}

                    {getContentPosition(item) === 'overlay-text' && (
                      <div className="overlay-text-border"></div>
                    )}

                    {!isEmpty(documentToReactComponents(item?.bodyText, markdownRenderOptions)) && (
                      <div
                        className={`body-text text-sm md:text-md lg:mt-[18px] leading-[26px]  ${
                          getContentPosition(item) === 'overlay-text' && 'overlay-text-sub '
                        }
                         ${
                           (getContentPosition(item) === 'center-left' ||
                             getContentPosition(item) === 'left') &&
                           'text-left'
                         }
                        ${
                          (getContentPosition(item) === 'center-right' ||
                            getContentPosition(item) === 'bottom-right') &&
                          'text-right'
                        }
                        promo-title-sub `}
                      >
                        {documentToReactComponents(item?.bodyText, markdownRenderOptions)}
                      </div>
                    )}
                    <div
                      className={`button-group-wrap mt-[30px] flex flex-col lg:flex-row   ${
                        getContentPosition(item) === 'left'
                          ? 'justify-start items-start'
                          : getContentPosition(item) === 'center-left'
                          ? 'justify-start items-start'
                          : getContentPosition(item) === 'center-right' ||
                            getContentPosition(item) === 'bottom-right'
                          ? 'justify-end items-end'
                          : 'justify-center items-center'
                      }`}
                    >
                      {item?.showCta && ctaLeft.url && ctaLeft.label && (
                        <CTA
                          className={`${ctaClassName} ${ctaRight?.label ? 'lg:mr-5' : ''}`}
                          label={ctaLeft.label}
                          inlineStyles={buttonStyle}
                          openInNewTab={ctaLeft.openInNewTab}
                          openVideoPlayer={ctaLeft.openVideoPlayer}
                          url={ctaLeft.url}
                          background={ctaLeft.bgColor}
                          moduleProps={props}
                        />
                      )}
                      {item?.showCta && showRightCta && (
                        <CTA
                          className={`${ctaClassName}`}
                          label={ctaRight.label}
                          inlineStyles={buttonStyle}
                          openInNewTab={ctaRight.openInNewTab}
                          openVideoPlayer={ctaRight.openVideoPlayer}
                          url={ctaRight.url}
                          background={ctaRight.bgColor}
                          moduleProps={props}
                        />
                      )}
                      {item?.heroType === 'Hero PLP Filter Center' && (
                        <button
                          type="button"
                          className={`hero-plp-filter-btn hero-plp-filter-btn-wht ${
                            item.spaceBelowTextContent ? 'mt-8' : 'mt-6'
                          }`}
                        >
                          <Icon width="20" height="20" icon={'heroFilter'} fill="0 0 20 20" />
                          <span className="text-white uppercase">{'Filter & Sort'}</span>
                        </button>
                      )}
                      {item?.heroType === 'Hero PLP Filter White BG' && (
                        <button
                          type="button"
                          className={`hero-plp-filter-btn hero-plp-filter-btn-blk ${
                            item.spaceBelowTextContent ? 'mt-8' : 'mt-6'
                          }`}
                        >
                          <Icon width="20" height="20" icon={'heroFilterBlk'} fill="0 0 20 20" />
                          <span className="text-[#4C4442] uppercase">{'Filter & Sort'}</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {item?.heroType === 'Hero PLP Bottom Spread' && (
                <div className={`absolute left-0 text-left w-[100%] bottom-[80px]`}>
                  <div className={`hero-text-color mx-[24px] lg:mx-[80px]`}>
                    {!isEmpty(item.eyebrow) && (
                      <p
                        className={`font-bold leading-tight text-xs md:text-sm uppercase tracking-[0.16em] text-left`}
                      >
                        {item.eyebrow}
                      </p>
                    )}

                    {!isEmpty(item.headline) && (
                      <h1
                        className={`text-3xl uppercase text-[32px] md:text-[50px] font-bold leading-[38px] md:leading-[50px] ${
                          item.spaceBelowTextContent ? 'mt-[25px]' : 'm-3'
                        }`}
                      >
                        {item.headline}
                      </h1>
                    )}

                    {!isEmpty(documentToReactComponents(item?.bodyText, markdownRenderOptions)) && (
                      <div
                        className={`body-text text-sm md:text-md lg:mt-[18px] leading-[26px] tracking-widest promo-title-sub`}
                      >
                        {documentToReactComponents(item.bodyText, markdownRenderOptions)}
                        {/* check if the training page then display button based on shed group */}
                        {item?.showCta && ctaLeft.url && ctaLeft.label && (
                          <CTA
                            className={`${ctaClassName} float-right`}
                            label={ctaLeft.label}
                            openInNewTab={ctaLeft.openInNewTab}
                            openVideoPlayer={ctaLeft.openVideoPlayer}
                            url={ctaLeft.url}
                            inlineStyles={buttonStyle}
                            background={ctaLeft.bgColor}
                            moduleProps={props}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {item?.productData?.length > 0 && (
                <div className={`absolute left-0 text-left w-[100%] bottom-[80px]`}>
                  <div className="relative z-10">
                    <div
                      className={`absolute bottom-55 bottom-30 p-1 rounded-full circle-section cursor-pointer ${
                        item?.heroType === 'Hero Center Right' ||
                        item?.heroType === 'Hero Bottom Right' ||
                        item?.heroType === 'Hero PLP Bottom Spread'
                          ? 'md:left-20 left-4'
                          : 'md:right-20 right-4'
                      }`}
                      onClick={(e) => {
                        setShowProductMenu(true)
                      }}
                      onMouseEnter={() => {
                        setShowProductMenu(true)
                      }}
                    >
                      <div
                        className={`${
                          showProductMenu !== null
                            ? showProductMenu
                              ? ''
                              : 'plus-icon-on-show'
                            : 'plus-icon-on-reload'
                        }`}
                      >
                        <Icon
                          className={`cursor-pointer text-white fill-current w-7 h-7`}
                          viewBox={`0 0 36 36`}
                          size={36}
                          icon="plus-circle"
                        />
                      </div>
                      {/* {showProductMenu ? ( */}
                        <ProductPopUp
                          showProductMenu={showProductMenu}
                          setShowProductMenu={setShowProductMenu}
                          products={productsList}
                          heroType={item?.heroType}
                          moduleProps={props}
                        />
                      {/* ) : null} */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

      <style jsx>
        {`
          .hero-text-color {
            color: ${getTextColor()};
          }
          .imageBackground {
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
          }
          .imageBackground::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: ${item?.overlayType === 'Dark'
              ? `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.2) 39.06%, rgba(0, 0, 0, 0) 68.23%, rgba(0, 0, 0, 0) 100%),
            linear-gradient(359.88deg, rgba(0, 0, 0, 0.5) 0.35%, rgba(0, 0, 0, 0.2) 32.33%, rgba(0, 0, 0, 0) 78.07%),
            linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) );`
              : item?.overlayType === 'Light'
              ? `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))`
              : item?.overlayType === 'Empty' && 'none'};
          }
          .plus-icon-on-reload {
            animation: plusIconReload 0.8s forwards;
          }

          @keyframes plusIconReload {
            0% {
              transform: rotateZ(0deg);
            }

            100% {
              transform: rotateZ(0deg);
            }
          }
          .plus-icon-on-show {
            animation: plusIcon 0.8s forwards;
          }

          @keyframes plusIcon {
            0% {
              transform: rotateZ(180deg);
            }

            100% {
              transform: rotateZ(90deg);
            }
          }
        `}
      </style>
    </div>
  )
}
export default ModuleHeroSection
